<!-- 安全中心--设置密码--设置密码 第二部-->
<template>
  <div class="verifysetpas">
    <div class="top">
      <el-row class='head'>
        <el-col :span="12">
          <logotitle :title='["设置支付密码","修改支付密码","修改登录密码","找回密码"][this._spf(this.$route.query.types)]'></logotitle>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
    </div>
    <div class="stepcnt">
      <verifystep class='verifystep' :steps='1'></verifystep>
      <div class="verifybox pas">
        <div class="icon">
          <span><svg><use xlink:href="#iconwancheng"></use></svg></span>
          <h6>身份验证成功！{{this._spf(this.$route.query.types)==3?'10分钟内有效！':''}}</h6>
        </div>
     <!--    <div class="group-pas"  v-if='this.$route.query.types==1||this.$route.query.types==2'>
          <input type="password" autocomplete="new-password" :placeholder='["","输入原支付密码","输入原登录密码"][this.$route.query.types]'  v-model='oldpassword'>
          <p class="err" v-show='errors.oldpassword'>{{errors.oldpassword}}</p>
        </div> -->
        <div class="group-pas" :class='{paserr:errors.pass}'>
          <input type="password" autocomplete="new-password" :placeholder='["设置支付密码","设置新支付密码","设置新登录密码","设置新登录密码"][this._spf(this.$route.query.types)]' @focus='removepas("pass")' v-model='password'>
          <p class="err" v-show='errors.password'>{{errors.password}}</p>
        </div>
        <div class="group-pas" :class='{paserr:errors.passs}'>
          <input type="password" autocomplete="new-password" placeholder="确认密码"  @focus='removepas("passs")' v-model='passwords'>
          <p class="err" v-show='errors.passwords'>{{errors.passwords}}</p>
        </div>
        <button @click='nexts' class="next" :disabled="disabled">下一步</button>
      </div>
    </div>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
import verifystep from '@/components/verifystep/verifystep.vue';//图片标题组件
// import getcodebtn from '@/components/getcodebtn/getcodebtn.vue';//获取验证码按钮组件-types属性切换接口
import {mixin,setOrGetCode,usermethod} from '../assets/js/common.js' //公用js
export default {
  name: 'verifysetpas',
  mixins: [mixin],//混入
  props: {
    msg: String
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
          name: "description",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        },
        {
          name: "Keywords",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        }
      ],
    }
  },
  data(){
    return {
      htmltitle:'安全中心-微微定',
      // oldpassword:null,
      password:null,
      passwords:null,
      errors:{
        password:null,
        pass:false,
        passwords:null,
        passs:false,
        // oldpassword:null,
      },
      disabled:false
    }
  },
  components: {
    logotitle,
    verifystep,
    // getcodebtn
  },
  methods:{
    nexts(){
      // 下一步验证
      var _flag = true
      // this.errors.oldpassword=null
      this.errors.password=null
      this.errors.passwords=null
      // if(this.$route.query.types==1||this.$route.query.types==2){
      //   if(!this.oldpassword){
      //     _flag = false
      //     if(this.$route.query.types==1){
      //       this.errors.oldpassword = '请输入原支付密码'
      //     }
      //     if(this.$route.query.types==2){
      //       this.errors.oldpassword = '请输入原登录密码'
      //     }
      //   }
      // }
      if(!this.password){
        _flag = false
        this.errors.pass = true
        if(this._spf(this.$route.query.types)==1||this._spf(this.$route.query.types)==2){
          this.errors.password = '请输入新密码'
        }else {
          this.errors.password = '请输入支付密码'
        }
      }
      // if(!this.password){
      //   _flag = false
      //   this.errors.pass = true
      //   this.errors.password = '请输入密码'
      // }
      if(this._spf(this.$route.query.types)==0||this._spf(this.$route.query.types)==1){
        if(this.password&&this.password.length!=6){
          _flag = false
          this.errors.pass = true
          this.errors.password = '请输入长度为6位的支付密码'
        }
      }else{
        if(this.password&&(this.password.length<6||this.password.length>18)){
          _flag = false
          this.errors.password = '请输入6~18位的登录密码'
        }
      }
      if(!this.passwords){
        _flag = false
        this.errors.passs = true
        if(this._spf(this.$route.query.types)==1||this._spf(this.$route.query.types)==2){
          this.errors.passwords = '请再次输入新密码'
        }else {
          this.errors.passwords = '请再次输入密码'
        }
      }
      if(this.password!=this.passwords){
        _flag = false
        this.errors.passs = true
        this.errors.passwords = '两次密码不一致！'
      }
      // 成功跳转下一步
      if(_flag){
        this.disabled = true
        if(this._spf(this.$route.query.types)==0){
          if(setOrGetCode({method:'get'})){
            this.setPays(setOrGetCode({method:'get'}))
          }else{
            // 验证失效
            this.$Message({
                message: '验证超时！',
                type: 'error',
                duration:1500,
                // offset:100
            });
            this.$router.go(-1);
          }
        }
        if(this._spf(this.$route.query.types)==1){
          if(setOrGetCode({method:'get'})){
            this.changePays(setOrGetCode({method:'get'}))
          }else{
            // 验证失效
            this.$Message({
                message: '验证超时！',
                type: 'error',
                duration:1500,
                // offset:100
            });
            this.$router.go(-1);
          }
        }
        if(this._spf(this.$route.query.types)==2){
          if(setOrGetCode({method:'get'})){
            this.changeLogs(setOrGetCode({method:'get'}))
          }else{
            // 验证失效
            this.$Message({
                message: '验证超时！',
                type: 'error',
                duration:1500,
                // offset:100
            });
            this.$router.go(-1);
          }
        }
        if(this._spf(this.$route.query.types)==3){
          if(setOrGetCode({method:'get'})){
            this.changeLogsExp(setOrGetCode({method:'get'}))
          }else{
            // 验证失效
            this.$Message({
                message: '验证超时！',
                type: 'error',
                duration:1500,
                // offset:100
            });
            this.$router.go(-1);
          }
        }
      }
    },
    changeLogsExp(code){
      // 找回密码--修改登录密码
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/FindPassWord?rexCode='+code+'&newpwd='+this.password+'&phone='+this._spf(this.$route.query.phone),this.keys())
        .then(res => {
            if(res.data.Success){
              this.disabled = false
              if(res.data.Data.state===0){
                window.sessionStorage.removeItem('phonecode')
                this._router({then:()=>{
                  this.$router.push('/verifysetresult?types=3.html')
                }})
              }else if(res.data.Data.state===4){
                // 验证失效
                this.$Message({
                    message: '验证超时！',
                    type: 'error',
                    duration:1500,
                    // offset:100
                });
                this.$router.go(-1);
              }
            }else{
              this.disabled = false
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      }})
    },
    changeLogs(code){
      // 修改登录密码
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/UpdateUserPassWord?NewPassWord='+this.password+'&phone='+this.$store.state.user.Mobile+'&rexCode='+code,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.disabled = false
              if(res.data.Data.state===0){
                window.sessionStorage.removeItem('phonecode')
                this._router({then:()=>{
                  this.$router.push('/verifysetresult?types=2.html')
                },exp:true,catch:()=>{
                  this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                  // this._open({path: "/accountlogin",query: {redirect:'/'}})
                }})
              }else if(res.data.Data.state===4){
                // 验证失效
                this.$Message({
                    message: '验证超时！',
                    type: 'error',
                    duration:1500,
                    // offset:100
                });
                this.$router.go(-1);
              }else if(res.data.Data.state===3){
                // 验证失效
                this.$Message({
                    message: res.data.Data.message,
                    type: 'error',
                    duration:1500,
                    // offset:100
                });
              }
            }else{
              this.disabled = false
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    changePays(code){
      // 修改支付密码
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/UpdatePayPassWord?NewPayPassWord='+this.password+'&phone='+this.$store.state.user.Mobile+'&rexCode='+code,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.disabled = false
              if(res.data.Data.state===0){
                window.sessionStorage.removeItem('phonecode')
                this._router({then:()=>{
                  this.$router.push('/verifysetresult?types=1.html')
                },exp:true,catch:()=>{
                  this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                  // this._open({path: "/accountlogin",query: {redirect:'/'}})
                }})
              }else if(res.data.Data.state===4){
                // 验证失效
                this.$Message({
                    message: '验证超时！',
                    type: 'error',
                    duration:1500,
                    // offset:100
                });
                this.$router.go(-1);
              }else if(res.data.Data.state===3){
                // 验证失效
                this.$Message({
                    message: res.data.Data.message,
                    type: 'error',
                    duration:1500,
                    // offset:100
                });
              }
            }else{
              this.disabled = false
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    setPays(code){
      // 设置支付密码
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/SetPayPassWord?PayPassWord='+this.password+'&phone='+this.$store.state.user.Mobile+'&rexCode='+code,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.disabled = false
              if(res.data.Data.state===0){
                window.sessionStorage.removeItem('phonecode')
                if(usermethod({method:'get'})){
                  // 每次访问路由判断用户数据是否过期默认不操作30分钟---需要验证用户信息的页面跳转的事件手动判断存储的用户信息是否存在
                  var _users = usermethod({method:'get'})
                  // 保持用户信息
                  _users.user.IsSetPaypassWord = true
                  this.$store.dispatch("cmtSetUser",_users.user)
                  //重新存储---每次存储重置缓存时间
                  usermethod({method:'set',user:_users.user});
                }
                this._router({then:()=>{
                  this.$router.push('/verifysetresult?types=0.html')
                },exp:true,catch:()=>{
                  this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                  // this._open({path: "/accountlogin",query: {redirect:'/'}})
                }})
              }else if(res.data.Data.state===4){
                // 验证失效
                this.$Message({
                    message: '验证超时！',
                    type: 'error',
                    duration:1500,
                    // offset:100
                });
                this.$router.go(-1);
              }
            }else{
              this.disabled = false
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
            // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    removepas(v){
      // 去掉 红框
      // this.errors[v] = false
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
  },
  filters:{

  }
}
</script>
<style  lang="scss" scoped>
.verifysetpas {
  padding-bottom:40px;
  .top {
    width: 100%;background: #fff;padding:10px 0px;box-shadow: 0px 3px 5px 0px rgba(212,212,212,0.4);position: relative;z-index: 9;
    .head {
      width: 1200px;margin:0 auto;
      .rechargestep {
        float:right;margin-top:5px;
      }
    }
  }
  .stepcnt {
    width: 100%;background: #fff;padding-top:25px;padding-bottom:130px;
    .verifystep {
      margin-bottom:70px;
    }
    .verifybox {
      width: 380px;margin:0 auto;
      .icon {
        margin-bottom:30px;
        span {
          display: block;width: 50px;height: 50px;background: #cccccc;border-radius: 50%;margin:0 auto;line-height: 50px;text-align: center;
          svg {
            width: 35px;height: 35px;vertical-align: -13px;fill: #fff;
          }
        }
        h6 {
          text-align: center;height: 35px;line-height: 35px;font-size: 14px;
        }
      }
      .next {
        width: 380px;height: 48px;border-radius: 5px;background: #f44e55;color:#fff;font-size: 16px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .pas {
      .icon {
        span {
          background: #5fddb2;
          svg {
            vertical-align: -15px;margin-left:2px;
          }
        }
      }
      .group-pas {
        margin-bottom:30px;position: relative;
        input {
          width: 358px;height: 48px;border:1px solid #e8e8e8;border-radius: 5px;padding-left:20px;
          &::placeholder {
            font-size:14px;color:#d0d0d0;
          }
        }
        .err {
          position: absolute;font-size: 12px;color:#f44e55;left:0px;top:52px;
        }
      }
      .paserr {
        input {
          // border-color:#f44e55;
        }
      }
    }
  }
}
</style>
